<!--添加或编辑设备-->
<template>
  <div class="contentAdd videoManageDetail">
    <div class="contentboxAdd">
      <div class="contentboxAdd_head">
        <div class="contentboxAdd_title" v-if="isEdit == false">
          <i class="iconfont icontianjia"></i>
          <span>添加</span>
        </div>
        <div class="contentboxAdd_title" v-else>
          <i class="iconfont icontianjia"></i>
          <span>编辑</span>
        </div>
        <el-button
          :loading="loading"
          class="button keepButton"
          @click="keepClickFun"
          >保存</el-button
        >
        <el-button
          class="button cancelButton"
          @click="backClickFun"
          style="margin-left: 10px !important"
          >返回
        </el-button>
      </div>
      <div class="contentboxAdd_nav">
        <vue-scroll :ops="ops">
          <div class="contentboxAdd_box">
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              :label-width="labelWidth"
              size="small"
              class="demo-ruleForm"
              @submit.native.prevent
            >
              <div class="border_contentboxAdd">
                <SuperForm
                  :customArrKey="customArrKey"
                  :formList="formList"
                  :formInline="ruleForm"
                  :listJson="listJson"
                  @inputFun="treeSelectChange"
                  ref="childList"
                >
                  <template v-slot:videoFile>
                    <div class="videoFile">
                      <el-upload
                        class="upload"
                        ref="upload"
                        :accept="videoFileType"
                        action=""
                        :on-remove="videoHandleRemove"
                        :file-list="videoFileList"
                        :on-change="videoChangeFile"
                        :before-upload="videoBeforeUpload"
                        :auto-upload="false"
                      >
                        <el-button slot="trigger" size="small" type="primary"
                          >选择视频</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                          只能上传{{ videoFileType.split(".").join("") }}文件
                        </div>
                      </el-upload>
                    </div>
                  </template>
                  <template v-slot:imgFile>
                    <div class="imgFile">
                      <el-upload
                        class="upload"
                        ref="upload"
                        :accept="imgFileType"
                        action=""
                        :on-remove="imgHandleRemove"
                        :on-change="imgChangeFile"
                        :before-upload="imgBeforeUpload"
                        :auto-upload="false"
                        :file-list="imgFileList"
                      >
                        <el-button slot="trigger" size="small" type="primary"
                          >选择图片</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                          只能上传{{ imgFileType.split(".").join("") }}文件
                        </div>
                      </el-upload>
                    </div>
                  </template>
                </SuperForm>
              </div>
            </el-form>
            <div class="imgCon" v-if="imgBase64">
              <img :src="imgBase64" />
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import SuperForm from "@/common/module/SuperForm";
import formValidate from "@/utils/formValidate";
export default {
  name: "videoManageEdit",
  components: {
    SuperForm
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      ruleForm: {},
      rules: {},
      labelWidth: "190px",
      customArrKey: [],
      formList: [],
      listJson: {},
      loading: false,
      isEdit: false,
      videoFileType: ".mp4",
      imgFileType: ".jpg,.jpeg,.png,.gif,.bmp",
      imgFileList: [],
      videoFileList: [],
      imgBase64: "",
      videoFile: ""
    };
  },
  created() {
    this.findByDomainid();
    this.init();
    if (this.$route.query.id) {
      this.ruleForm.pkId = this.$route.query.id;
      this.detail();
      this.isEdit = true;
    }
  },
  methods: {
    changeTreeNew(val) {
      let arr = [];
      if (val.length !== 0) {
        val.map(item => {
          let obj = {};
          obj.id = item.typeCode;
          obj.label = item.text;
          if (item.nodes && item.nodes.length >= 1) {
            obj.children = this.changeTreeNew(item.nodes);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    findByDomainid() {
      this.$api("videoManage.getVideoTypeTree")
        .invoke()
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            let dataList = data.data;
            if (dataList) {
              // data.map(e => {
              //   e.label = e.name;
              //   e.id = e.code;
              // });
              console.log("data", data);
              let treedata = [];
              if (dataList.length) {
                dataList.map((e, i) => {
                  console.log(
                    "this.changeTreeNew(dataList)[i]",
                    this.changeTreeNew(dataList)[i]
                  );
                  treedata.push({
                    id: this.changeTreeNew(dataList)[i].id,
                    label: this.changeTreeNew(dataList)[i].label,
                    children: this.changeTreeNew(dataList)[i].children
                  });
                });
              } else {
                treedata = [];
              }
              this.ruleForm.typecode = treedata[0].id;
              const len = this.formList.length;
              for (let i = 0; i < len; i++) {
                if (this.formList[i].name === "typecode") {
                  // this.formList[i].option.push(...data);
                  this.formList[i].treedata = treedata;
                }
              }
            }
          } else {
          }
        })
        .catch(() => {});
    },
    init() {
      if (this.$route.query.id) {
        this.formList = [
          {
            clearable: true,
            name: "typecode",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请选择视频类型",
            label: "视频类型",
            type: "treeselect",
            treedata: [],
            validmessage: "视频类型不能为空！",
            validtriggers: "change",
            validrules: "isNonEmpty"
          },
          {
            clearable: true,
            name: "videoname",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请输入视频名称",
            label: "视频名称",
            type: "text",
            validmessage: "视频名称不能为空！",
            validtriggers: "blur",
            validrules: "isNonEmpty"
          },
          {
            clearable: true,
            name: "memo",
            width: "100%",
            showhide: true,
            disabled: false,
            placeholder: "请输入视频简介",
            label: "视频简介",
            type: "textarea"
          },
          {
            clearable: true,
            name: "zruser",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请输入责任人",
            label: "责任人",
            type: "text"
          },
          {
            clearable: true,
            name: "status",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请选择视频状态",
            label: "视频状态",
            type: "selectst",
            option: [
              {
                labelname: "正常",
                value: "0"
              },
              {
                labelname: "废弃",
                value: "1"
              }
            ]
          },
          {
            clearable: true,
            name: "imgFile",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "",
            label: "视频预览图片",
            type: "slot"
          }
        ];
      } else {
        this.formList = [
          {
            clearable: true,
            name: "typecode",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请选择视频类型",
            label: "视频类型",
            type: "treeselect",
            treedata: [],
            validmessage: "视频类型不能为空！",
            validtriggers: "change",
            validrules: "isNonEmpty"
          },
          {
            clearable: true,
            name: "videoname",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请输入视频名称",
            label: "视频名称",
            type: "text",
            validmessage: "视频名称不能为空！",
            validtriggers: "blur",
            validrules: "isNonEmpty"
          },
          {
            clearable: true,
            name: "memo",
            width: "100%",
            showhide: true,
            disabled: false,
            placeholder: "请输入视频简介",
            label: "视频简介",
            type: "textarea"
          },
          {
            clearable: true,
            name: "zruser",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请输入责任人",
            label: "责任人",
            type: "text"
          },
          {
            clearable: true,
            name: "status",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "请选择视频状态",
            label: "视频状态",
            type: "selectst",
            option: [
              {
                labelname: "正常",
                value: "0"
              },
              {
                labelname: "废弃",
                value: "1"
              }
            ]
          },
          {
            clearable: true,
            name: "videoFile",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "",
            label: "视频文件",
            type: "slot"
          },
          {
            clearable: true,
            name: "imgFile",
            width: "50%",
            showhide: true,
            disabled: false,
            placeholder: "",
            label: "视频预览图片",
            type: "slot"
          }
        ];
      }
      this.ruleForm = {
        pkId: "",
        typecode: "", // 视频类型
        videoname: "", // 视频名称
        memo: "", // 视频简介
        zruser: "", // 责任人
        status: "0" // 视频状态
      };
      this.rules = formValidate.validateFun(this.formList);
    },
    // 详情接口
    detail() {
      let obj = {
        pkId: this.ruleForm.pkId
      };
      this.$api("videoManage.getSdlVideoById")
        .invoke(obj)
        .then(({ data: { data } }) => {
          let datas = data;
          this.ruleForm = {
            typecode: datas.typecode, // 视频类型
            videoname: datas.videoname, // 视频名称
            memo: datas.memo, // 视频简介
            zruser: datas.zruser, // 责任人
            status: datas.status // 视频状态
          };
          this.imgBase64 = datas.videopreview;
          if (datas.videopreview) {
            this.imgFileList = [
              {
                name: "视频预览图片.png",
                url: datas.videopreview
              }
            ];
          }
        });
    },
    // 下拉选项改变，手动校验
    treeSelectChange() {
      // treeselect插件tigger没有相关的触发校验的事件（change和blur都不能触发），需要在它的值改变时手动调用验证
      this.$refs.ruleForm.validateField("typecode");
    },
    // 保存
    keepClickFun() {
      let that = this;
      that.$refs["ruleForm"].validate(valid => {
        if (valid) {
          that.loading = true;
          let formData = new FormData();
          let params;
          let url = "";
          if (that.$route.query.id) {
            that.ruleForm.pkId = that.$route.query.id;
          }

          // 编辑和新增不走同一接口，编辑只能编辑基本信息，不能编辑视频
          if (this.$route.query.id) {
            url = "videoManage.updateSdlVideo";
            params = {};
            for (let key in that.ruleForm) {
              params[key] = that.ruleForm[key];
            }
            params.videopreview = this.imgBase64;
          } else {
            params = new FormData();
            for (let key in that.ruleForm) {
              params.append(key, that.ruleForm[key]);
            }
            params.append("videopreview", this.imgBase64);
            params.append("file", this.videoFile);
            url = "videoManage.saveSdlVideo";
            if (!this.videoFile) {
              that.loading = false;
              that.$message({
                showClose: true,
                message: "请上传视频！",
                type: "error",
                duration: 1000
              });
              return;
            }
          }
          that
            .$api(url)
            .invoke(params)
            .then(({ data: { code, data } }) => {
              if (code === 1) {
                that.loading = false;
                that.$message({
                  showClose: true,
                  message: "保存成功！",
                  type: "success",
                  duration: 1000
                });
                that.backClickFun();
              } else {
                that.loading = false;
                that.$message({
                  showClose: true,
                  message: data.msg,
                  type: "error",
                  duration: 1000
                });
              }
            });
        }
      });
    },
    // 返回
    backClickFun() {
      this.$router.push({
        path: "/index/leftMenuIndex/videoManage",
        query: {
          menuid: this.$route.query.menuid,
          sysmodel: this.$route.query.sysmodel,
          moduleName: this.$route.query.moduleName
        }
      });
    },
    videoChangeFile(file, fileList) {
      this.videoFileList = [file];
      this.videoFile = file.raw;
    },
    videoHandleRemove(file, fileList) {
      this.videoFileList = [];
      this.videoFile = "";
    },
    videoBeforeUpload(file) {
      let extensionName = file.name.split(".").pop();
      if (!this.videoFileType.includes(extensionName.toLocaleLowerCase())) {
        this.$message.warning(
          `只能上传${this.videoFileType.split(".").join("")}格式文件`
        );
        return false;
      }
    },
    imgChangeFile(file, fileList) {
      let reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = () => {
        this.imgBase64 = reader.result;
        this.imgFileList = [
          {
            name: "视频预览图片.png",
            url: reader.result
          }
        ];
      };
    },
    imgHandleRemove(file, fileList) {
      this.imgFileList = [];
      this.imgBase64 = "";
    },
    imgBeforeUpload(file) {
      let extensionName = file.name.split(".").pop();
      if (!this.imgFileType.includes(extensionName.toLocaleLowerCase())) {
        this.$message.warning(
          `只能上传${this.imgFileType.split(".").join("")}格式文件`
        );
        return false;
      }
    }
  }
};
</script>
<style scoped lang="less">
.videoFile,
.imgFile {
  /deep/.el-upload__tip {
    display: inline-block;
    margin-left: 15px;
  }
}
// .imgFile {
//   /deep/.el-upload-list {
//     display: none;
//   }
// }
.imgCon {
  margin-top: 25px;
  width: 320px;
  height: 240px;
  margin-left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

var RULES = {
  /*不能为空*/
  isNonEmpty: function (rule, value, callback) {
    //不能为空
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  },
  /*类似备注、描述富文本类型的输入框使用该属性验证是否为空*/
  isNonEmptyHtmleditor: function (rule, value, callback) {
    //类似备注、描述富文本类型的输入框使用该属性验证是否为空
    //不能为空
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  },
  /*手机号（不包括空）*/
  isMobile: function (rule, value, callback) {
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback();
    } else {
      //是否为手机号码
      if (!/(^1[3|5|6|7|8|9][0-9]{9}$)/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    }
  },
  /*固定电话（不包括空）*/
  isTelephone: function (rule, value, callback) {
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback();
    } else {
      //是否固定电话
      //if (!/^(^0\d{2}-?\d{8}$)|(^0\d{3}-?\d{7}$)|(^\(0\d{2}\)-?\d{8}$)|(^\(0\d{3}\)-?\d{7}$)$/.test(value)) {
      if (!/^(^0\d{2}-?\d{8}$)|(^0\d{3}-?\d{7}$)$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    }
  },
  /*电话-手机号（不包括空）*/
  isTelephoneOrMobile: function (rule, value, callback) {
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback();
    } else {
      if (value.indexOf("-") > -1) {
        //是否固定电话
        //if (!/^(^0\d{2}-?\d{8}$)|(^0\d{3}-?\d{7}$)|(^\(0\d{2}\)-?\d{8}$)|(^\(0\d{3}\)-?\d{7}$)$/.test(value)) {
        if (!/^(^0\d{2}-?\d{8}$)|(^0\d{3}-?\d{7}$)$/.test(value)) {
          callback(new Error(rule.message));
        } else {
          callback();
        }
      } else {
        //是否为手机号码
        if (!/(^1[3|5|6|7|8][0-9]{9}$)/.test(value)) {
          callback(new Error(rule.message));
        } else {
          callback();
        }
      }
    }
  },
  /*邮箱（不包括空）*/
  isEmail: function (rule, value, callback) {
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback();
    } else {
      //是否为邮箱
      if (!/(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    }
  },
  /*传真（不包括空）*/
  isFax: function (rule, value, callback) {
    if (value == undefined || value == null || value.toString().trim() == "") {
      callback();
    } else {
      //是否为邮箱
      if (!/^(\d{3,4}-)?\d{7,8}$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    }
  },
  /*身份证（不包括空）*/
  isCarid: function (rule, value, callback) {
    //身份证号
    if (value && value != "") {
      if (
        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|[Xx])$/.test(
          value
        )
      ) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  /*纯英文（不包括空）*/
  onlyEn: function (rule, value, callback) {
    //纯英文
    if (value && value != "") {
      if (!/^[A-Za-z]+$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  /*纯中文（不包括空）*/
  onlyZh: function (rule, value, callback) {
    //纯中文
    if (value && value != "") {
      if (!/^[\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  /*数字验证（包括空）*/
  isNum: function (rule, value, callback) {
    //数字类型
    if (!/^\d+$/.test(value)) {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  },
  /*数字验证（不包括空）*/
  ifHaveisNum: function (rule, value, callback) {
    //如果有值就验证是数字类型
    if (value != null && value != undefined && value != "") {
      if (!/\d+$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  /*数字验证包含小数（包括空）*/
  onlyNum: function (rule, value, callback) {
    //数字包含小数
    if (!/^[0-9]+([.][0-9]+){0,1}$/.test(value)) {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  },
  /*数字验证包含小数（不包括空）*/
  ifHaveValueMustNum: function (rule, value, callback) {
    //如果有值进行验证（数字包含小数）
    if (value != null && value != undefined && value != "") {
      if (!/^[0-9]+([.][0-9]+){0,1}$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  isgeshu: function (rule, value, callback) {
    //如果有值进行验证（数字包含小数）
    if (value != null && value != undefined && value != "") {
      if (!/^\d{1,20}(\.\d{1,2})?$/.test(value)) {
        //20是限制小数位数
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  /*整数验证（包括空）*/
  onlyInt: function (rule, value, callback) {
    //整数
    if (!/^(0|[1-9][0-9]*)$/.test(value)) {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  },
  /*整数验证（不包括空）*/
  onlyIntWx: function (rule, value, callback) {
    if (value != null && value != undefined && value != "") {
      //不为空就验证整数
      if (!/^(0|[1-9][0-9]*)$/.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  /*经度验证（不包括空）*/
  isLongitude: function (rule, value, callback) {
    /*经度判断*/
    var longrg =
      /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
    if (value && value != "") {
      if (longrg.test(value)) {
        callback();
      } else {
        callback(new Error(rule.message));
      }
    } else {
      callback();
    }
  },
  /*纬度验证（不包括空）*/
  isLatitude: function (rule, value, callback) {
    /*纬度判断*/
    var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
    if (value && value != "") {
      if (latreg.test(value)) {
        callback();
      } else {
        callback(new Error(rule.message));
      }
    } else {
      callback();
    }
  },
  /*范围*/
  /*[null,200]  blur  最多可输入200个字！*/

  /* 行政区划需要选择到市 */
  isSelectToCity(rule, value, callback) {
    return callback();
    if (value == 0 || !value) callback(new Error(rule.message));
    else callback();
  },
  /*数组长度验证*/
  isLength: function (rule, value, callback) {
    //不能为空
    // console.log(value);
    if (value == undefined || value == null || value.length == 0) {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  },
  /*大于0，小于100*/
  isZeroToHundred: function (rule, value, callback) {
    if (value && value != "") {
      if (Number(value) > 0 && Number(value) < 100) {
        callback();
      } else {
        callback(new Error(rule.message));
      }
    } else {
      callback();
    }
  }
};

/*拼接验证*/
function validateFun(data) {
  var validateObj = {};
  for (var i = 0; i < data.length; i++) {
    for (var item in data[i]) {
      if (
        data[i].validrules &&
        data[i].validmessage &&
        data[i].validtriggers &&
        data[i].validrules != "" &&
        data[i].validmessage != "" &&
        data[i].validtriggers != ""
      ) {
        validateObj[data[i].name] = [];
        var arr2 = data[i].validmessage.split("$+$"); //错误提示
        var arr3 = data[i].validtriggers.split("$+$"); //触发方式
        var isRequired = data[i].required; // 显示星号
        var arr1;
        if (typeof data[i].validrules == "object") {
          /*一个值-范围*/
          arr1 = data[i].validrules; //验证规则
          if (arr3[0] == "") {
            arr3[0] = "blur";
          }
          if (arr2[0] == "") {
            arr2[0] = "填写有误！";
          }
          var min, max;
          min = arr1[0] ? arr1[0] : "";
          max = arr1[1] ? arr1[1] : "";
          validateObj[data[i].name].push({
            min: parseInt(min),
            max: parseInt(max),
            message: arr2[0],
            trigger: arr3[0]
          });
        } else {
          /*一个值或者多个值*/
          arr1 = data[i].validrules.split("$+$"); //验证规则
          for (var k = 0; k < arr1.length; k++) {
            if (arr3[k] == "") {
              arr3[k] = "blur";
            }
            if (arr2[k] == "") {
              arr2[k] = "填写有误！";
            }
            if (
              arr1[k].substr(0, 1) == "[" &&
              arr1[k].substr(arr1[k].length - 1, 1) == "]"
            ) {
              var min, max;
              var lngArr = arr1[k].slice(1, arr1[k].length - 1);
              var lngArr1 = lngArr.split(",");
              min = lngArr1[0] ? lngArr1[0] : "";
              max = lngArr1[1] ? lngArr1[1] : "";
              validateObj[data[i].name].push({
                min: parseInt(min),
                max: parseInt(max),
                message: arr2[k],
                trigger: arr3[k],
                required: isRequired
              });
            } else {
              if (
                data[i].customRulesFun &&
                typeof data[i].customRulesFun === "function"
              ) {
                validateObj[data[i].name].push({
                  validator: data[i].customRulesFun,
                  trigger: arr3[k],
                  message: arr2[k],
                  required: isRequired
                });
              } else {
                validateObj[data[i].name].push({
                  validator: RULES[arr1[k]],
                  trigger: arr3[k],
                  message: arr2[k],
                  required: isRequired
                });
              }
            }
          }
        }
      }
    }
  }
  return validateObj;
}

export default {
  validateFun: validateFun,
  RULES: RULES
};
